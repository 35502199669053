import { Injectable } from '@angular/core';
import { config } from '@tag/graphql';
import type { GraphQLParams, Vendor } from '@tag/graphql';
import { Query, gql } from 'apollo-angular';

export interface Response {
  vendors: { items: Vendor[]; totalCount: number };
}

@Injectable({
  providedIn: 'root',
})
export class VendorsGQL extends Query<Response, GraphQLParams<'vendor'>> {
  document = gql(config.vendor.query);
}
