import { Injectable } from '@angular/core';
import { config } from '@tag/graphql';
import type { Facility, GraphQLParams } from '@tag/graphql';
import { Query, gql } from 'apollo-angular';

export interface Response {
  facilities: { items: Facility[]; totalCount: number };
}

@Injectable({
  providedIn: 'root',
})
export class FacilitiesGQL extends Query<Response, GraphQLParams<'facility'>> {
  document = gql(config.facility.query);
}
